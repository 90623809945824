@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  @apply text-center text-4xl font-bold text-white;
}

.button {
  @apply rounded-full py-2 px-5 mt-5 bg-mainColor w-max text-white text-center;
}

.inactive-button {
  @apply rounded-full py-2 px-5 mt-5 bg-gray-600 w-max text-white text-center cursor-not-allowed; 
}

.cancel-button {
  @apply rounded-full py-2 px-5 mt-5 w-max text-white text-center;
}

.invalid-input {
  @apply text-red-500 italic;
}

